import React from 'react';
import classnames from 'classnames';
import css from 'styled-jsx/css';

interface FadeOnMountProps {
  delay?: number;
  duration?: number;
  bypass?: boolean;
  children: React.ReactElement;
}

const FadeOnMount: React.FC<FadeOnMountProps> = ({
  children,
  delay = 100,
  duration = 400,
  bypass = false,
}) => {
  const child = React.Children.only(children);

  const { className, style, ...restChildProps } = child.props;

  const styleInternal = {
    ...style,
    animationDuration: `${duration}ms`,
    animationDelay: `${delay}ms`,
  };

  const sjsx = css.resolve`
     {
      animation-name: fadeIn;
      animation-duration: 300ms;
      animation-delay: 100ms;
      animation-fill-mode: forwards;
      opacity: 0;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `;

  const childClone = React.cloneElement(child, {
    ...restChildProps,
    className: classnames(className, sjsx.className),
    style: styleInternal,
  });

  if (bypass) return <>{children}</>;

  return (
    <>
      {childClone}
      {sjsx.styles}
    </>
  );
};

export default FadeOnMount;
