import classnames from 'classnames';

interface StickyProps {
  zIndex?: number;
  top?: number | string;
  bottom?: number | string;
  isEnabled?: boolean;
  style?: React.CSSProperties;
}

const Sticky: React.FC<StickyProps> = ({
  zIndex = 1,
  top,
  bottom,
  children,
  isEnabled = true,
  style,
}) => {
  // default to top
  if (top === undefined && bottom === undefined) {
    top = 0;
  }

  return (
    <div
      style={{ ...style, top, bottom, zIndex }}
      className={classnames({ isEnabled })}
    >
      {children}
      <style jsx>{`
        .isEnabled {
          position: sticky;
        }
      `}</style>
    </div>
  );
};

export default Sticky;
