import { useState, useEffect, RefObject } from 'react';

const WindowIntersectionObserver =
  // eslint-disable-next-line compat/compat
  process.browser && window.IntersectionObserver;

const useIsOverflowing = ({
  rootRef,
  targetRef,
  fallbackValue,
  skip,
}: {
  fallbackValue: boolean;
  rootRef: RefObject<HTMLElement>;
  targetRef: RefObject<HTMLElement>;
  skip?: boolean;
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (skip) return;

    // don't attempt to observe when browser lacks api
    if (!WindowIntersectionObserver) {
      setIsOverflowing(fallbackValue);
      return;
    }

    const { current: el } = rootRef;
    const { current: targetEl } = targetRef;

    if (!el || !targetEl) {
      return;
    }

    const observer = new WindowIntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Due to pixel rounding sometimes Chrome always sees the
          // target to be overflowing by sub-pixels, even when visually it's not.
          const isOverflowing = entry.intersectionRatio < 0.99;

          setIsOverflowing(isOverflowing);
        });
      },
      {
        root: el,

        // due to pixel rounding (from ems or %) we need
        // to loosen the threshold to catch these edge-cases
        threshold: 0.98,
      }
    );

    observer.observe(targetEl);

    return () => observer.disconnect();
  }, [rootRef, targetRef, skip, fallbackValue]);

  return isOverflowing;
};

export default useIsOverflowing;
