import tinycolor from 'tinycolor2';

export const darkenColor = (color: string, amount: number) => {
  return tinycolor(color).darken(amount).desaturate(30).toString();
};

export const stringToColor = (string: string) =>
  `#${intToRGB(hashCode(string))}`;

function hashCode(str: string) {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return hash;
}

function intToRGB(i: number) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}

/**
 * Converts a hex color to RGBA string with a given alpha.
 *
 * @param hex - The hex color code (e.g., "#FFFFFF" or "#FFF").
 * @param alpha - The alpha value as a number between 0 and 1.
 * @returns The RGBA color string (e.g., "rgba(255, 255, 255, 0.5)").
 */
export const toRgba = (hex: string, alpha: number) => {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // If the hex is in the shorthand format (e.g., "#FFF"), expand it.
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // Convert the hex to an RGB triplet.
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const isColorLight = (hexColor: string) => {
  // Convert hex color string to RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Calculate luminance
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

  // A common threshold for determining if the color is light is 150,
  // but this can be adjusted if needed.
  return luminance > 150;
};
