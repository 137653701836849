import withSpacing, { WithSpacingProps } from '~/app/lib/hocs/withSpacing';
import React, { RefObject } from 'react';

interface GradientProps extends WithSpacingProps {
  from?: string;
  to: string;
  angle?: number;
  style?: React.CSSProperties;
  className?: string;
  testId?: string;
  endAt?: string;
  nodeRef?: RefObject<HTMLDivElement>;
}

const Gradient = withSpacing<GradientProps>(
  ({
    from = 'transparent',
    to,
    angle = 0,
    endAt = '100%',
    children,
    style,
    className,
    testId,
    nodeRef,
  }) => (
    <div ref={nodeRef} style={style} className={className} data-testid={testId}>
      {children}
      <style jsx>{`
        div {
          background-image: linear-gradient(
            ${angle}deg,
            ${to} 0%,
            ${from} ${endAt}
          );
        }
      `}</style>
    </div>
  )
);

export default Gradient;
