import { raf } from './raf';

const throttledRaf = (fn) => {
  let pending = false;

  return (param) => {
    if (pending) return;
    pending = true;

    raf(() => {
      pending = false;
      fn(param);
    });
  };
};

export default throttledRaf;
